html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: YogaSans, YogaSansBold, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  height: 100%;
}

@font-face {
  font-family: 'YogaSans';
  src: url("./assets/fonts/5619112/16b9f76d-9f5c-49de-821f-0e53e13a034a.eot?#iefix") format("eot"), url("./assets/fonts/5619112/c470e0b2-7ac2-469b-a23e-16f3c53425c5.woff2") format("woff2"), url("./assets/fonts/5619112/6300c510-54d1-4230-b1b7-cfb54248868a.woff") format("woff"), url("./assets/fonts/5619112/17156472-5b1c-4315-b2ac-79a2faecd038.ttf") format("truetype");
}

@font-face {
  font-family: 'YogaSansBold';
  src: url("./assets/fonts/5659673/6f642d1c-dacc-4d95-91ad-59b597480ed6.eot?#iefix") format("eot"), url("./assets/fonts/5659673/f2f3f772-7c20-4424-9026-8bd2f09ca865.woff2") format("woff2"), url("./assets/fonts/5659673/9dc79667-8f11-4956-8e66-bafba69f8970.woff") format("woff"), url("./assets/fonts/5659673/9dfe0560-0dfb-4ad7-b33a-c04cb3e27d3a.ttf") format("truetype");
}

